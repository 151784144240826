import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useQueryParam } from "use-query-params";
import { navigate } from 'gatsby';
import { Button } from '../../components/button.component';

import { RegisterLayout } from '../../components/register-layout.component';
import { verifyAccountToken } from '../../services/users.service';
import { setUser } from '../../states/authentication.slice';

export default function Home() {
  
  const dispatch = useDispatch();
  const [ error, setError ] = useState('');
  const { t } = useTranslation();

  const [ email ] = useQueryParam('email');
  const [ token ] = useQueryParam('token');
  const [ isLoading, setIsLoading ] = useState(true);
  const [userFullName, setUserFullName] = useState('');

  useEffect(() => {
    verifyAccountToken(email, token)
      .then(response => {
        dispatch(setUser(response.data.user));
        setUserFullName(response.data.userFullName);
        if (response.data.user?.type === 'ORGANIZATION') {
          navigate('/organization');
        }
      })
      .catch(e => {
        setError(e.response.data.message);
      })
      .finally(() => setIsLoading(false));
  }, [ email, token ]);

  return <RegisterLayout disableSecureLayout={true} title={t('Email confirmation')} className="verify-confirmation" backLocation="/">
      {isLoading ? (
        <p>loading ...</p>
      ) : (
        <>
        <div className="section section--verification-1"></div>
        <div className="section section--verification-2">
          <h1 className="t-h2">{t('Welcome')}</h1>
          {userFullName !== '' && (<p className="t-sans t-p1">{userFullName}様</p>)}
          <p className="t-sans t-p1 mb-25 t-grey7 finish__instruction">{t('Account registration is complete. Please continue to register the test kit.')}</p>
          <div className="buttons">
              <Button to="/register">{t('Test Kit')}</Button>
          </div>
        </div>
        </>
      )}
  </RegisterLayout>;
}
